import React from "react"

import Layout from "../../components/layout"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Content from "../../components/content"
import { Bar } from 'react-chartjs-2'
import SEO from "../../components/seo"

const TaxPage = ({data}) => {
    let rates = data.graphAPI.taxRates
    let meta = data.graphAPI.contentTaxRate.meta
    let content = data.graphAPI.contentTaxRate.content
    return (
        <Layout>
            <SEO title={meta.title} />
            <div className="title-section">
                <h1 className="title is-size-1">{meta.title}</h1>
            </div>
            <Content content={content} />
            <div style={{maxWidth: '600px'}}>
            <Bar data={{
                labels: rates.map((rate) => { return (rate.year)}).reverse(),
                datasets: [
                  {
                    label: "Debt Service",
                    backgroundColor: "rgba(101,101,193,1)",
                    strokeColor: "rgba(101,101,193,0.8)",
                    highlightFill: "rgba(101,101,193,0.75)",
                    highlightStroke: "rgba(101,101,193,1)",
                    data: rates.map((rate) => { return (rate.interestSinkingRate.toFixed(5))}).reverse()
                  },
                  {
                    label: "Maintenance and Operation",
                    backgroundColor: "rgba(101,101,193,0.5)",
                    strokeColor: "rgba(101,101,193,0.8)",
                    highlightFill: "rgba(101,101,193,0.75)",
                    highlightStroke: "rgba(101,101,193,1)",
                    data: rates.map((rate) => { return (rate.maintenanceOperationsRate.toFixed(5))}).reverse()
                  }
                ]
              }}
              options={{
                scales: {
                  xAxes: [{
                    stacked: true
                  }],
                  yAxes: [{
                    stacked: true
                  }]
                },
                tooltips: {
                  mode: 'label',
                  displayColors: false,
                  callbacks: {
                      label: function(tooltipItem, data) {
                          var corporation = data.datasets[tooltipItem.datasetIndex].label;
                          var valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                          var total = 0;
                          for (var i = 0; i < data.datasets.length; i++)
                              total += data.datasets[i].data[tooltipItem.index];
                          if (tooltipItem.datasetIndex != data.datasets.length - 1) {
                            //   return corporation + " : $" + valor.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
                              return corporation + " : $ " + valor;
                          } else {
                              return [corporation + " : $ " + valor];
                          }
                      }
                  }
                },
                scaleBeginAtZero: true
              }} width="600" height="400" />
            </div>
            <div className="content-align">
            <div className="table-container">
            <table className="table" style={{marginTop: '3rem'}}>
                <thead>
                    <tr>
                        <td>Year</td>
                        <td>Debt Service</td>
                        <td>Maintenance</td>
                        <td>Total Tax Rate</td>
                    </tr>
                </thead>
                <tbody>
                    {rates.map(rate => (
                        <tr key={rate.id}>
                            <td>{rate.year}</td>
                            <td>{(rate.interestSinkingRate).toFixed(5)}</td>
                            <td>{(rate.maintenanceOperationsRate).toFixed(5)}</td>
                            <td>{(rate.interestSinkingRate+rate.maintenanceOperationsRate+rate.roadRate).toFixed(5)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
            </div>
        </Layout>
    )
}

export default TaxPage

export const query = graphql`
    query taxPageById {
        graphAPI {
            contentTaxRate {
                meta {
                    id
                    title
                    description
                }
                content {
                    ... on GRAPHAPI_ComponentPageBaseContent {
                        ...baseContent
                    }
                    ... on GRAPHAPI_ComponentPageImage {
                        ...pageImage
                    }
                    ... on GRAPHAPI_ComponentPageLinkCardWithImage {
                        ...linkCardWithImage
                    }
                    ... on GRAPHAPI_ComponentPageSplitContentImage {
                        ...splitContentImage
                    }
                    ... on GRAPHAPI_ComponentPageGallery {
                        ...pageGallery
                    }
                    ... on GRAPHAPI_ComponentPageLinkList {
                        ...pageLinkList
                    }
                }
            }
            taxRates(sort: "year:desc") {
                year
                maintenanceOperationsRate
                interestSinkingRate
                roadRate
                contractRate
                orderLevyingTaxes {
                    ...publicDocument
                }
            }
        } 
    }
`


